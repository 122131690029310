<!--The content below is only a placeholder and can be replaced.-->

<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="navbar-expand-md">
    <div class="navbar-brand" class="bg-dark text-light">
      <img class="navbar-brand" src="/assets/workbench.svg" alt="🔨" />
      <span class="d-none d-md-inline ml-2">Workbench</span></div>
  </div>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
  (click)="isNavbarCollapsed = !isNavbarCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item h-100 ml-md-4">
        <a class="nav-link" routerLink="/notes">Notes</a>
      </li>
      <li class="nav-item h-100">
        <a class="nav-link" routerLink="/times">Time</a>
      </li>
      <li class="nav-item h-100">
        <a class="nav-link" routerLink="/canvas">Canvas</a>
      </li>
      <li *ngIf="isHome" class="nav-item h-100">
        <p class="nav-link">Home: {{ isHome }}</p>
      </li>
    </ul>
  </div>
  <app-login class="navbar-text"></app-login>
</nav>

<div class="container mt-4">
  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
