import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private rootUrl : string = "http://192.168.178.53:8081";

  public isHome : BehaviorSubject<boolean>;

  private devices : string[];

  constructor(private http : HttpClient) {
    this.isHome = new BehaviorSubject(false);
  }

  getDevices() {
    this.http.get<string[]>(`${this.rootUrl}/devices`).subscribe(
      ret => {
        this.devices = [];
        this.devices = ret;
      }
    )
  }

  public check() {
    this.http.get(`${this.rootUrl}/hello`, { responseType: 'text' as const }).subscribe(
      ret => {
        this.isHome.next(true);
        this.getDevices();
        return true;
      },
      e => {
        this.isHome.next(false);
        return false;
      }
    );
  }
}
