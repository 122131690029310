import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'notes', loadChildren: () => import('./notes/notes.module').then(m => m.NotesModule) },
  { path: 'times', loadChildren: () => import('./timetracking/timetracking.module').then(m => m.TimetrackingModule) },
  { path: 'canvas', loadChildren: () => import('./canvas/canvas.module').then(m => m.CanvasModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
