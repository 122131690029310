<div class="flex-md-row">
  <div *ngIf="isLoggedIn" class="p-2 align-self-md-end">
      <a href="/api/admin/" target="_blank">{{ this.getUsername() }}</a>
  </div>
  <form *ngIf="!isLoggedIn" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="p-2 align-self-md-end">
    <div class="form-row">
      <div class="col">
        <label for="username" class="sr-only">Username</label>
        <input type="text" formControlName="username" class="form-control-sm"
        [ngClass]="{ 'is-invalid': submitted }" placeholder="Username" />
      </div>
      <div class="col">
        <label for="password" class="sr-only">Password</label>
        <input type="password" formControlName="password" class="form-control-sm" [ngClass]="{ 'is-invalid': submitted }" placeholder="Passwort"/>
      </div>
      <div class="col">
        <button [disabled]="loading" class="btn btn-primary">Login</button>
        <p *ngIf="loading">login…</p>
      </div>
    </div>

  </form>
</div>
