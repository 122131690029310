import { Component, OnInit } from '@angular/core';

import { LoginService } from './services/login.service';
import { HomeService } from './services/home.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  loggedIn : boolean;
  isHome : boolean;
  isNavbarCollapsed : boolean = true;

  private homeTimeout;

  constructor(private loginService : LoginService,
    private homeService : HomeService) {}

  ngOnInit() {
    this.loginService.loggedIn.subscribe(
      val => this.loggedIn = val
    );
    this.homeService.isHome.subscribe(
      val => this.isHome = val
    );
    this.homeTimeout = setTimeout(() => { this.homeService.check(); }, 1000);
  }
}
