import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoggedIn : boolean = false;

  loginForm : FormGroup;
  loading : boolean = false;
  submitted : boolean = false;

  constructor(private formBuilder : FormBuilder,
    private loginService : LoginService) { }

  getUsername() : string {
    return this.loginService.username;
  }

  onSubmit() {
    if (this.loading) {
      console.log("already loading");
      return;
    }
    if (this.loginForm.invalid) {
      console.log("loginForm invalid");
      return;
    }
    this.loading = true;
    this.loginService.login(
      this.loginForm.controls.username.value,
      this.loginForm.controls.password.value);
    this.submitted = true;
  }

  ngOnInit() {
    console.log("initializing login...");
    this.loginService.loggedIn.subscribe(val => this.isLoggedIn = val);
    this.loginForm = this.formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
    this.loginService.restore();
  }

}
