import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class LoginService {

  private base_url : string;

  private token : string;

  public username : string;

  public loggedIn : BehaviorSubject<boolean>;

  private domain : string;
  private cookiepath : string = "/";
  private secure : boolean = true;
  private daysforcookie = 30;

  constructor(private http : HttpClient,
    private cookieService : CookieService) {
    this.loggedIn = new BehaviorSubject(false);
    this.base_url = environment.apiUrl;
    if (environment.domain == '127.0.0.1') {
      this.domain = environment.domain;
    }
    else {
      this.domain = "https://" + environment.domain;
    }

  }

  public getBaseUrl() {
    return this.base_url;
  }

  public getHttpHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + this.token
      })
    };
    return httpOptions;
  }

  private saveToken() {
    sessionStorage.removeItem("token");
    sessionStorage.setItem("token", this.token);
    sessionStorage.removeItem("username");
    sessionStorage.setItem("username", this.username);
    this.saveToCookie();
  }

  private loadToken() {
    let token = sessionStorage.getItem("token");
    if (token != null && token.length > 6) {
      this.token = token;
      this.username = sessionStorage.getItem("username");
      if (! this.cookieService.check("username")
        || ! this.cookieService.check("token")) {
          this.saveToCookie();
        }
    }
    if (this.username == null || this.username == "") {
      let token = this.cookieService.get("token");
      let username = this.cookieService.get("username");
      if (token != null && token != "" && username != null && username != "") {
        this.token = token;
        this.username = username;
        console.log("cookie loaded");
      }
      else {
        console.log("cookie not loaded! ", username, token);
      }
    }
  }

  private saveToCookie() {
    let date : Date = new Date();
    date = new Date(date.valueOf() + ((1000 * 60 * 60 * 24) * this.daysforcookie));
    this.cookieService.set("username", this.username, date, this.cookiepath, this.domain, this.secure);
    this.cookieService.set("token", this.token, date, this.cookiepath, this.domain, this.secure);
    console.log("cookie saved");
  }

  login(username : string, password : string) {
    let tosend = {'username': username, 'password': password};
    this.http.post(this.base_url + "/api-token-auth/", tosend).subscribe(
      val => {
        console.log("received ", val);
        this.token = val["token"];
        this.username = username;
        this.loggedIn.next(true);
        this.saveToken();
      }
    );
  }

  restore() {
    console.log("trying to load login...");
    this.loadToken();
    if (this.token != null && this.token != "") {
      console.log("login successfull.")
      this.loggedIn.next(true);
    }
    else {
      console.log("re-login failed...");
    }
  }
}
